import {datadogRum} from '@datadog/browser-rum';
import {effect, signal} from '@preact/signals';
import Cookies from 'js-cookie';

type ExperimentGlobal = Window & {Experiment?: {key: string | null}};

/**
 * The Experiment class is a singleton that manages feature flags.
 * It allows you to set and retrieve the value of a feature flag based on a key.
 * Its internal state is persisted to cookies, and it also logs feature flag evaluations to Datadog RUM.
 * Because it uses Preact signals under the hood, you can subscribe to changes in the feature flags.
 */
export default class Experiment {
  private static _instance: Experiment;
  private static _cookieKey = 'Experiment.key';
  private readonly _key = signal<string | null>(null);

  /**
   * Private constructor to prevent instantiation.
   * Use `Experiment.getInstance()` to get the singleton instance.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  /**
   * Retrieves the key associated with the experiment.
   *
   * @returns The key as a string if it exists, otherwise null.
   */
  public get key(): string | null {
    return this._key.peek();
  }

  /**
   * Initializes the global Experiment object.
   *
   * This method sets up the global Experiment object by checking for an existing
   * cookie and a key in the global window object. If a cookie is found, it sets
   * the corresponding flag to true. If a key is found in the global window's
   * Experiment object, it also sets the corresponding flag to true.
   *
   * @param globalWindow - The global window object, defaulting to the current window.
   */
  public static initGlobal(globalWindow = window as ExperimentGlobal): void {
    const cookie = Experiment.getCookie();
    const experiment = Experiment.getInstance();

    if (cookie) {
      experiment.setFlag(cookie, true);
    }

    if (globalWindow.Experiment?.key) {
      experiment.setFlag(globalWindow.Experiment.key, true);
    }

    globalWindow.Experiment = experiment;
  }

  /**
   * Returns the singleton instance of the `Experiment` class.
   * If the instance does not already exist, it creates a new one.
   *
   * @returns The singleton instance of the `Experiment` class.
   */
  public static getInstance(): Experiment {
    return (Experiment._instance = Experiment._instance ?? new Experiment());
  }

  private static getCookie(): string | null {
    return Cookies.get(Experiment._cookieKey) ?? null;
  }

  private static setCookie(key: string | null): void {
    if (key) {
      Cookies.set(Experiment._cookieKey, key);
    } else {
      Cookies.remove(Experiment._cookieKey);
    }
  }

  /**
   * Sets the feature flag for the given key to the specified value.
   * Also handles persisting the key in a cookie, and adding the evaluation to Datadog RUM.
   *
   * @param key - The key of the feature flag to set.
   * @param value - The value to set for the feature flag. If true, the flag is enabled; if false, the flag is disabled. Optional, defaults to true.
   */
  public setFlag(key: string, value = true): void {
    const prevKey = this._key.value;

    if (value) {
      this._key.value = key;
    } else if (prevKey === key) {
      this._key.value = null;
    }

    if (prevKey && prevKey !== this._key.value) {
      datadogRum.addFeatureFlagEvaluation(prevKey, false);
    }

    datadogRum.addFeatureFlagEvaluation(key, value);

    Experiment.setCookie(this._key.value);
  }

  /**
   * Retrieves the value of a feature flag based on the provided key.
   * Also adds the evaluation to Datadog RUM.
   * This value is subscribable using Preact signals.
   *
   * @param key - The key of the feature flag to retrieve.
   * @returns The value of the feature flag.
   *
   * @example In a preact component
   * ```ts
   * // This is automatically reactive using Preact signals
   * const featureEnabled = getFlag('some_feature');
   * return <div>{featureEnabled ? 'Feature is enabled' : 'Feature is disabled'}</div>;
   * ```
   *
   * @example In a non-reactive context
   * ```ts
   * import {effect} from '@preact/signals';
   *
   * // The effect will run whenever the feature flag changes
   * effect(() => {
   *  const featureEnabled = getFlag('some_feature');
   *  console.log(`Feature is ${featureEnabled ? 'enabled' : 'disabled'}`);
   * });
   * ```
   */
  public getFlag(key: string): boolean {
    const value = this._key.value === key;
    datadogRum.addFeatureFlagEvaluation(key, value);
    return value;
  }

  /**
   * Registers a callback function to be invoked whenever the value of the specified feature flag changes.
   *
   * @param key - The key of the feature flag to watch.
   * @param callback - The function to call with the updated value of the feature flag.
   */
  public watchFlag(key: string, callback: (value: boolean) => void): void {
    effect(() => {
      callback(this.getFlag(key));
    });
  }
}
