import {logError} from '@/utilities/log';
import Cookies from 'js-cookie';

export function getCookieFeatureFlags(): Map<string, string> {
  const ffCookie = Cookies.get('featureFlags');
  if (!ffCookie) {
    return new Map();
  }
  try {
    return new Map(
      Object.entries(JSON.parse(ffCookie) as Record<string, string>)
    );
  } catch (e) {
    logError(e, {message: 'FailedToParseFeatureFlagsCookie', ffCookie});
    return new Map();
  }
}

export function clearCookieFeatureFlags() {
  Cookies.remove('featureFlags');
}

export function updateCookieFeatureFlags(flags: Map<string, string>) {
  try {
    const currentFlags = getCookieFeatureFlags();
    const newFlags = new Map([...currentFlags, ...flags]);
    Cookies.set('featureFlags', JSON.stringify(Object.fromEntries(newFlags)));
  } catch (error) {
    logError(error, {message: 'ErrorUpdatingFeatureFlagsCookie', flags});
  }
}
